import React, { Component } from 'react';
import axios from 'axios';
import { URL } from '../../util/ambienteActions';
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil';
import { withRouter } from 'react-router-dom';

const topTrechos = [
    {
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        idCidadeOrigem: 2498,
        nomeCidadeDestinoComUF: 'Brasília-DF',
        idCidadeDestino: 1,        
        ordem: 1
    },    
    {
        nomeCidadeOrigemComUF: 'São Paulo-SP',
        idCidadeOrigem: 5848,
        nomeCidadeDestinoComUF: 'Brasília-DF',
        idCidadeDestino: 1,
        ordem: 2
    },    
    {
        nomeCidadeOrigemComUF: 'São Paulo-SP',
        idCidadeOrigem: 5848,
        nomeCidadeDestinoComUF: 'Belo Horizonte-MG',
        idCidadeDestino: 2498,        
        ordem: 3
    },
    {
        nomeCidadeOrigemComUF: 'Rio de Janeiro-RJ',
        idCidadeOrigem: 4160,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,
        ordem: 4
    },
    {
        nomeCidadeOrigemComUF: 'Brasília-DF',
        idCidadeOrigem: 1,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 5
    },    
    {
        nomeCidadeOrigemComUF: 'Curitiba-PR',
        idCidadeOrigem: 3788,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 6
    },    
    {
        nomeCidadeOrigemComUF: 'Salvador-BA',
        idCidadeOrigem: 6036,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 7
    },
    {
        nomeCidadeOrigemComUF: 'Fortaleza-CE',
        idCidadeOrigem: 1023,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 8
    },
    {
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        idCidadeOrigem: 2498,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 9
    },
    {
        nomeCidadeOrigemComUF: 'Recife-PE',
        idCidadeOrigem: 3417,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 10
    },
    {
        nomeCidadeOrigemComUF: 'Porto Alegre-RS',
        idCidadeOrigem: 4742,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 11
    },
    {
        nomeCidadeOrigemComUF: 'Manaus-AM',
        idCidadeOrigem: 794,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 12
    },
    {
        nomeCidadeOrigemComUF: 'Goiânia-GO',
        idCidadeOrigem: 126,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 13
    },
    {
        nomeCidadeOrigemComUF: 'Florianópolis-SC',
        idCidadeOrigem: 5002,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 14
    },
    {
        nomeCidadeOrigemComUF: 'Belém-PA',
        idCidadeOrigem: 2085,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        idCidadeDestino: 5848,        
        ordem: 15
    }
];

export class CardsTopTrechos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupedTrechos: [],
        };
    }

    buscarHorarios(idCidadeOrigem, idCidadeDestino) {
        axios.get(`${URL}/trecho/public/origem/${idCidadeOrigem}/destino/${idCidadeDestino}/nome-normalizado`, SLOW_REQUEST_CONFIG)
            .then(resp => {
                if (resp.data) {
                    const { nomeCidadeOrigemComUFNormalizado, nomeCidadeDestinoComUFNormalizado } = resp.data;
                    let url = `/passagem-de-onibus/${nomeCidadeOrigemComUFNormalizado}/${nomeCidadeDestinoComUFNormalizado}`;
                    this.props.history.push(url);
                }
            })
    }

    retornarGrupoDe5(valores) {
        const grupo = [];    
        for (let i = 0; i < valores.length; i += 5) {
            const array = valores.slice(i, i + 5);
            grupo.push(array);
        }    
        return grupo;
    }

    render() {        
        const groupedTrechos = this.retornarGrupoDe5(topTrechos);

        return (
            <div id='container-topTrechos1' className='container-toptrechos'>
                <div id='containerTituloTrechos' className='titulo-topTrechos'>
                    <span>
                        <b id='tituloTrechos'> Top Redes de Pesquisa </b>
                    </span>                    
                </div>
                <div className='container-groupedTrechos'>
                    {groupedTrechos.map((group, groupIndex) => (
                        <div key={groupIndex} id={`containertopTrechos${groupIndex}`} className='containertopTrechos'>
                            {group.map((trecho, index) => (
                                <div className='col-md-4 mb-4' key={index}>
                                    <div className='card-topTrechos'>
                                        <a id='topTrechosLink' onClick={() => this.buscarHorarios(trecho.idCidadeOrigem, trecho.idCidadeDestino)}>
                                            <div className='cardTrecho'>
                                                <div className='container-origemDestino'>
                                                    <div className='icon-topOrigem'>
                                                        <span id='destinoIcons' className="fa fa-map-marker"></span>
                                                        <h3 className='trechoOrigem'>{trecho.nomeCidadeOrigemComUF}</h3>
                                                    </div>
                                                    <div className='icon-topDestino'>
                                                        <span id='arrowTrechos' className='fas fa-arrow-right'></span>
                                                        <h3 className='trechoDestino'>{trecho.nomeCidadeDestinoComUF}</h3>
                                                        <span id='trechoDestinoIcons' className="fas fa-map-pin"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default withRouter(CardsTopTrechos);
