import React, { Component } from 'react';
import BuscaPassagem from '../passagem/BuscaPassagem';
import { URL } from '../../util/ambienteActions';
import axios from 'axios';
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil';
import imagemPadrao from '../../estilo/comum/img/ImagemTrechoPadrao.webp'
import {Card} from 'react-bootstrap';

const ordemCidades = [
    'Rio-Branco-AC', 'Maceio-AL', 'Macapa-AP', 'Manaus-AM', 'Salvador-BA', 'Fortaleza-CE', 'Brasília-DF', 'Vitoria-ES', 'Goiania-GO', 'Sao-Luis-MA', 'Cuiaba-MT', 'Campo-Grande-MS', 'Belo-Horizonte-MG', 'Belem-PA', 'Joao-Pessoa-PB', 'Curitiba-PR', 'Recife-PE', 'Teresina-PI', 'Rio-de-Janeiro-RJ', 'Natal-RN', 'Porto-Alegre-RS', 'Boa-Vista-RR',
    'Florianopolis-SC', 'Sao-Paulo-SP', 'Aracaju-SE', 'Palmas-TO', 'Cruzeiro-do-Sul-AC', 'Arapiraca-AL', 'Santana-AP', 'Parintins-AM', 'Feira-de-Santana-BA', 'Caucaia-CE', 'Taguatinga-DF', 'Vila-Velha-ES', 'Aparecida-de-Goiania-GO', 'Imperatriz-MA', 'Varzea-Grande-MT', 'Dourados-MS', 'Uberlandia-MG', 'Ananindeua-PA', 'Campina-Grande-PB', 'Londrina-PR',
    'Jaboatao-dos-Guararapes-PE', 'Parnaiba-PI', 'Sao-Goncalo-RJ', 'Mossoro-RN', 'Caxias-do-Sul-RS', 'Rorainopolis-RR', 'Joinville-SC', 'Guarulhos-SP', 'Nossa-Senhora-do-Socorro-SE', 'Araguaina-TO', 'Tarauaca-AC', 'Palmeira-dos-Indios-AL', 'Laranjal-do-Jari-AP', 'Itacoatiara-AM', 'Vitoria-da-Conquista-BA', 'Juazeiro-do-Norte-CE', 'Serra-ES', 'Anapolis-GO',
    'Caxias-MA', 'Rondonopolis-MT', 'Tres-Lagoas-MS', 'Contagem-MG', 'Santarem-PA', 'Patos-PB', 'Maringa-PR', 'Olinda-PE', 'Picos-PI', 'Duque-de-Caxias-RJ', 'Parnamirim-RN', 'Pelotas-RS', 'Caracarai-RR', 'Blumenau-SC', 'Campinas-SP', 'Lagarto-SE', 'Gurupi-TO', 'Feijo-AC', 'Rio-Largo-AL', 'Oiapoque-AP', 'Manacapuru-AM', 'Itabuna-BA', 'Sobral-CE', 'Cariacica-ES',
    'Rio-Verde-GO', 'Timon-MA', 'Sinop-MT', 'Corumba-MS', 'Juiz-de-Fora-MG', 'Maraba-PA', 'Santa-Rita-PB', 'Ponta-Grossa-PR', 'Paulista-PE', 'Piripiri-PI', 'Nova-Iguacu-RJ', 'Sao-Jose-de-Mipibu-RN', 'Santa-Maria-RS', 'Alto-Alegre-RR', 'Sao-Jose-SC', 'Sao-Bernardo-do-Campo-SP', 'Itabaiana-SE', 'Porto-Nacional-TO'
];

export default class PaginaPrincipaisTrechos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            destinos: [],
            activePage: 1,
            totalRegistros: 0
        };
    }

    buscarHorarios(idCidadeOrigem, idCidadeDestino) {
        axios.get(`${URL}/trecho/public/origem/${idCidadeOrigem}/destino/${idCidadeDestino}/nome-normalizado`, SLOW_REQUEST_CONFIG)
            .then(resp => {
                if (resp.data) {
                    const { nomeCidadeOrigemComUFNormalizado, nomeCidadeDestinoComUFNormalizado } = resp.data;
                    let url = `/passagem-de-onibus/${nomeCidadeOrigemComUFNormalizado}/${nomeCidadeDestinoComUFNormalizado}`;
                    this.props.history.push(url);
                }
            })
    }

    componentDidMount() {
        axios.get(`${URL}/plataformas/public/trechos`, SLOW_REQUEST_CONFIG).then(resp => {
            if (resp.data) {
                const { totalRegistros, principaisTrechosDto } = resp.data;
                const destinosOrdenados = this.ordenarDestinos(principaisTrechosDto);
                this.setState({ destinos: destinosOrdenados, totalRegistros });
            }
        })
    }

    ordenarDestinos(destinos) {
        const cidadesVistas = new Set();

        const cidadesPrioritarias = destinos.filter(destino => {
            if (ordemCidades.includes(destino.nomeCidadeDestinoComUF) &&
                !cidadesVistas.has(destino.nomeCidadeDestinoComUF)) {
                cidadesVistas.add(destino.nomeCidadeDestinoComUF);
                return true;
            }
            return false;
        });

        const outrasCidades = destinos.filter(destino =>
            !cidadesPrioritarias.includes(destino)
        );

        return [...cidadesPrioritarias, ...outrasCidades];
    }

    render() {
        const { destinos } = this.state;
        return (
            <div className='conteudo'>
                <div>
                    <BuscaPassagem />
                </div>
                <div className='page-contato container mt-5'>
                    <h2 id='secao-trechos' className="gv-title">Confira as rotas que estamos operando:</h2>
                    <div className='container-trechos'>
                        {destinos.map((l, index) => (
                            <div className='card-principaisTrechos' key={index}>
                                {index < 1000 &&
                                    <div className='card p-3'>
                                        <div className='card-trecho'>
                                            <Card.Img variant="top" src={l.urlImagem ? l.urlImagem : imagemPadrao} alt="Imagem da Rota"/>
                                            <div className='box-icon mt-3'>
                                                <div className='icon-origem'></div>
                                                <span className="material-icons icon-origem-2" style={{color: 'red'}}>place</span>
                                                <h3 id='nomeTrechoOrigem' className='ml-3'>{l.nomeCidadeOrigemComUF}</h3>
                                                <h3 id='nomeTrechoDestino' className='ml-3 mt-2'>{l.nomeCidadeDestinoComUF}</h3>
                                            </div>
                                            <div id='buttonHorarios' className='line-btn'>
                                                <button onClick={() => this.buscarHorarios(
                                                    l.idCidadeOrigem,
                                                    l.idCidadeDestino
                                                )}
                                                        id='buttonHorarios' className="btn botaoLogar btn-buscaInterna btn-lista-trechos">
                                                    Horários
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

