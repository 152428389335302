import React, { Component } from 'react';
import { Button, Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import {selecionarEmpresa} from '../../actions/empresaActions'
import {connect} from 'react-redux';

const EMPRESAS_FIXAS = [
    {
        empresaId: 388,
        nomeFantasia: 'VIACAO AMARELINHO',
        nomeFantasiaNormalizado: 'viacao-amarelinho',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/0da338c4-3b9c-4e71-9504-61087bc01f09',
        hash: '0da338c4-3b9c-4e71-9504-61087bc01f09',
        orderm: 1

    },
    {
        empresaId: 558,
        nomeFantasia: 'VIP BRASIL',
        nomeFantasiaNormalizado: 'vip-brasil',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/a07f4793-4075-4c1b-9a93-a615e03e6700',
        hash: '',
        orderm: 2

    },    
    {
        empresaId: 593,
        nomeFantasia: 'BellaVita',
        nomeFantasiaNormalizado: 'bellavita',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/Passagens+de+%C3%94nibus+BellaVita%2C+mais+barata+no+Vexado+passagem_21-0164.webp',
        hash: 'Passagens+de+%C3%94nibus+BellaVita%2C+mais+barata+no+Vexado+passagem_21-0164.webp',
        orderm: 3

    },
    {
        empresaId: 577,
        nomeFantasia: 'Catedral',
        nomeFantasiaNormalizado: 'catedral',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/Passagens+de+%C3%94nibus+Catedral%2C+mais+barata+no+Vexado+passagem_25-4317.webp',
        hash: 'Passagens de Ônibus Catedral, mais barata no Vexado passagem_25-4317.webp',
        orderm: 4

    },
    {
        empresaId: 662,
        nomeFantasia: 'Itapemirim',
        nomeFantasiaNormalizado: 'itapemirim',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/e4151f5a-c609-406c-8ab6-187a8d70a81d',
        hash: 'f106e610-22a9-4051-befc-f67716872627',
        orderm: 5

    },    
    {
        empresaId: 622,
        nomeFantasia: 'Roderotas',
        nomeFantasiaNormalizado: 'roderotas',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/Passagens de Ônibus Roderotas, mais barata no Vexado passagem_23-4868.webp',
        hash: 'Passagens de Ônibus Roderotas, mais barata no Vexado passagem_23-4868.webp',
        orderm: 6

    },
    {
        empresaId: 666,
        nomeFantasia: 'Gontijo',
        nomeFantasiaNormalizado: 'gontijo',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/ecde28c7-5622-45f5-982a-df822249c459',
        hash: 'ecde28c7-5622-45f5-982a-df822249c459',
        orderm: 7

    },
    {
        empresaId: 583,
        nomeFantasia: 'Expresso Guanabara',
        nomeFantasiaNormalizado: 'expresso-guanabara',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/b29a1757-d0b2-4cf7-812f-5025de595db1',
        hash: 'b29a1757-d0b2-4cf7-812f-5025de595db1',
        orderm: 8

    },
    {
        empresaId: 600,
        nomeFantasia: 'Xavante',
        nomeFantasiaNormalizado: 'xavante',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/40b76b99-e58d-4174-97ff-ee6fb6d5e054',
        hash: '40b76b99-e58d-4174-97ff-ee6fb6d5e054',
        orderm: 9

    },
    {
        empresaId: 689,
        nomeFantasia: 'Eucatur',
        nomeFantasiaNormalizado: 'eucatur',
        logomarca: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/9ebc0785-1578-4716-a92a-2e148bbad955',
        hash: '9ebc0785-1578-4716-a92a-2e148bbad955',
        orderm: 10

    }
]

class CardsEmpresas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empresas: []
        };
    }

    handleCardClick = (empresa) => {
        this.props.selecionarEmpresa(empresa)
        const paginaTrechosEmpresa = {
            pathname: `/trechos-empresa/${empresa.nomeFantasiaNormalizado}`,
            state: { empresaId: empresa.empresaId }
        };
        this.props.history.push(paginaTrechosEmpresa);
    };

    handleButtonClick = () => {
        this.props.history.push('/pesquisar-empresas');
    };

    exibeEmpresa(empresa, index) {
        return (
            <div id='CardEmpresa' className="col-md-2" key={`${index}-${empresa.empresaId}`} data-valor={empresa.empresaId}>
                <Card className='card-Empresa'>
                    <a id='topTrechosLink' onClick={() => this.handleCardClick(empresa)}>
                        <Card.Body className='card-body-empresa'>
                            <Card.Img className='card-img-empresa' alt='Logo Empresa' variant="top" src={empresa.logomarca} />
                        </Card.Body>
                    </a>
                </Card>
            </div>
        )
    }

    retornarGrupoDe5(empresas) {
        const grupo = [];
        for (let i = 0; i < empresas.length; i += 5) {
            const array = empresas.slice(i, i + 5);
            grupo.push(array);
        }
        return grupo;
    }

    render() {
        const gruposDeEmpresas = this.retornarGrupoDe5(EMPRESAS_FIXAS.sort((a1, a2) => a1.orderm - a2.orderm));
        return (
            <div className='container-topEmpresas'>
                <div className='titulo-topTrechos'>
                    <span>
                        <b id='tituloTrechos'> Compre sua passagem na viação certa </b>
                    </span>
                    <span id='subTituloTopTrechos'>
                        <b id='subTituloTrechos'> A passagem de ônibus na viação de sua preferência </b>
                    </span>
                </div>
                <div className='containerCardsEmpresas'>
                    {gruposDeEmpresas.map((grupo, indexGrupo) => (
                        <div key={indexGrupo}>
                            <div className='containerGroupEmpresas'>
                                {grupo.map((empresa, index) => this.exibeEmpresa(empresa, index))}
                            </div>
                        </div>
                    ))}
                    <div className="container-button">
                        <Button id='button-pesquisarEmp' variant="primary" onClick={this.handleButtonClick}>
                            Pesquisar Empresas
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = state => (
    {
        selecionada: state.empresa.selecionada,
    }
)

export default withRouter(connect(mapStateToProps, { selecionarEmpresa })(CardsEmpresas))
