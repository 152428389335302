const dadosCardsDestinos=
[
    {
        'empresaId': 388,
        'idCidadeOrigem': 5848,
        'ufDestino': 'RJ',
        'nomeCidadeDestino': 'Rio de Janeiro - RJ',
        'nomeCidadeDestinoComUF': 'Rio de Janeiro - RJ',
        'nomeCidadeDestinoComUFNormalizado': 'riodejaneiro-rj',
        'idCidadeDestino': 4160,
        'ufOrigem': 'SP',
        'nomeCidadeOrigem': 'São Paulo-SP',
        'nomeCidadeOrigemComUF': 'São Paulo - SP',
        'nomeCidadeOrigemComUFNormalizado': 'saopaulo-sp',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Rio de Janeiro_16-0192.webp',
        'link': 'https://vexado.com.br/passagem-de-onibus/saopaulo-sp/riodejaneiro-rj'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 4160,
        'ufDestino': 'PR',
        'nomeCidadeDestino': 'Curitiba - PR',
        'nomeCidadeDestinoComUF': 'Curitiba - PR',
        'nomeCidadeDestinoComUFNormalizado': 'curitiba-pr',
        'idCidadeDestino': 3788,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Rio de Janeiro - RJ',
        'nomeCidadeOrigemComUF': 'Rio de Janeiro - RJ',
        'nomeCidadeOrigemComUFNormalizado': 'riodejaneiro-rj',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Curitiba_7-6301.webp',
        'link': 'https://vexado.com.br/passagem-de-onibus/riodejaneiro-rj/curitiba-pr'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 1,
        'ufDestino': 'GO',
        'nomeCidadeDestino': 'Goiânia - GO',
        'nomeCidadeDestinoComUF': 'Goiânia - GO*',
        'nomeCidadeDestinoComUFNormalizado': 'goiania-go',
        'idCidadeDestino': 126,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 89.90,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Goiânia_2-0609.webp',
        'link': 'https://vexado.com.br/passagem-de-onibus/brasilia-df/goiania-go'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 3788,
        'ufDestino': 'RS',
        'nomeCidadeDestino': 'Porto Alegre - RS',
        'nomeCidadeDestinoComUF': 'Porto Alegre - RS',
        'nomeCidadeDestinoComUFNormalizado': 'portoalegre-rs',
        'idCidadeDestino': 4742,
        'ufOrigem': 'PR',
        'nomeCidadeOrigem': 'Curitiba - PR',
        'nomeCidadeOrigemComUF': 'Curitiba - PR',
        'nomeCidadeOrigemComUFNormalizado': 'curitiba-pr',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Porto Alegre_14-1531.webp',
        'link': 'https://vexado.com.br/passagem-de-onibus/curitiba-pr/portoalegre-rs'
    },


    {
        'empresaId': 388,
        'idCidadeOrigem': 2498,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Belo Horizonte-MG',
        'nomeCidadeOrigemComUF': 'Belo Horizonte-MG',
        'nomeCidadeOrigemComUFNormalizado': 'belohorizonte-mg',
        'valor': 135,
        'quantidade': 302,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 1,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 135,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 4184,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Volta Redonda-RJ',
        'nomeCidadeOrigemComUF': 'Volta Redonda-RJ',
        'nomeCidadeOrigemComUFNormalizado': 'voltaredonda-rj',
        'valor': 249,
        'quantidade': 55,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2818,
        'ufDestino': 'SP',
        'nomeCidadeDestino': 'São Paulo-SP',
        'nomeCidadeDestinoComUF': 'São Paulo-SP*6ee8e111-adfc-4ee6-b5fd-fa5fe8580d43',
        'nomeCidadeDestinoComUFNormalizado': 'saopaulo-sp',
        'idCidadeDestino': 5848,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Ituiutaba-MG',
        'nomeCidadeOrigemComUF': 'Ituiutaba-MG',
        'nomeCidadeOrigemComUFNormalizado': 'ituiutaba-mg',
        'valor': 60,
        'quantidade': 32,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/6ee8e111-adfc-4ee6-b5fd-fa5fe8580d43'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 1,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Betim-MG',
        'nomeCidadeDestinoComUF': 'Betim-MG*',
        'nomeCidadeDestinoComUFNormalizado': 'betim-mg',
        'idCidadeDestino': 2504,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 189,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 5848,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Ituiutaba-MG',
        'nomeCidadeDestinoComUF': 'Ituiutaba-MG*',
        'nomeCidadeDestinoComUFNormalizado': 'ituiutaba-mg',
        'idCidadeDestino': 2818,
        'ufOrigem': 'SP',
        'nomeCidadeOrigem': 'São Paulo-SP',
        'nomeCidadeOrigemComUF': 'São Paulo-SP',
        'nomeCidadeOrigemComUFNormalizado': 'saopaulo-sp',
        'valor': 60,
        'quantidade': 12,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2498,
        'ufDestino': 'RJ',
        'nomeCidadeDestino': 'Angra dos Reis-RJ',
        'nomeCidadeDestinoComUF': 'Angra dos Reis-RJ*',
        'nomeCidadeDestinoComUFNormalizado': 'angradosreis-rj',
        'idCidadeDestino': 4093,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Belo Horizonte-MG',
        'nomeCidadeOrigemComUF': 'Belo Horizonte-MG',
        'nomeCidadeOrigemComUFNormalizado': 'belohorizonte-mg',
        'valor': 280,
        'quantidade': 11,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2504,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Betim-MG',
        'nomeCidadeOrigemComUF': 'Betim-MG',
        'nomeCidadeOrigemComUFNormalizado': 'betim-mg',
        'valor': 189,
        'quantidade': 10,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 4093,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Angra dos Reis-RJ',
        'nomeCidadeOrigemComUF': 'Angra dos Reis-RJ',
        'nomeCidadeOrigemComUFNormalizado': 'angradosreis-rj',
        'valor': 280,
        'quantidade': 8,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 1,
        'ufDestino': 'RJ',
        'nomeCidadeDestino': 'Angra dos Reis-RJ',
        'nomeCidadeDestinoComUF': 'Angra dos Reis-RJ*',
        'nomeCidadeDestinoComUFNormalizado': 'angradosreis-rj',
        'idCidadeDestino': 4093,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 300,
        'quantidade': 3,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2818,
        'ufDestino': 'SP',
        'nomeCidadeDestino': 'Botucatu-SP',
        'nomeCidadeDestinoComUF': 'Botucatu-SP*',
        'nomeCidadeDestinoComUFNormalizado': 'botucatu-sp',
        'idCidadeDestino': 5371,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Ituiutaba-MG',
        'nomeCidadeOrigemComUF': 'Ituiutaba-MG',
        'nomeCidadeOrigemComUFNormalizado': 'ituiutaba-mg',
        'valor': 149,
        'quantidade': 3,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 3138,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Santos Dumont-MG',
        'nomeCidadeOrigemComUF': 'Santos Dumont-MG',
        'nomeCidadeOrigemComUFNormalizado': 'santosdumont-mg',
        'valor': 269,
        'quantidade': 2,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 4180,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Três Rios-RJ',
        'nomeCidadeOrigemComUF': 'Três Rios-RJ',
        'nomeCidadeOrigemComUFNormalizado': 'tresrios-rj',
        'valor': 229,
        'quantidade': 1,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 102,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'GO',
        'nomeCidadeOrigem': 'Cristalina-GO',
        'nomeCidadeOrigemComUF': 'Cristalina-GO',
        'nomeCidadeOrigemComUFNormalizado': 'cristalina-go',
        'valor': 50,
        'quantidade': 1,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2983,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Paracatu-MG',
        'nomeCidadeOrigemComUF': 'Paracatu-MG',
        'nomeCidadeOrigemComUFNormalizado': 'paracatu-mg',
        'valor': 119,
        'quantidade': 1,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2843,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'João Pinheiro-MG',
        'nomeCidadeOrigemComUF': 'João Pinheiro-MG',
        'nomeCidadeOrigemComUFNormalizado': 'joaopinheiro-mg',
        'valor': 169,
        'quantidade': 1,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2498,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Belo Horizonte-MG',
        'nomeCidadeOrigemComUF': 'Belo Horizonte-MG',
        'nomeCidadeOrigemComUFNormalizado': 'belohorizonte-mg',
        'valor': 135,
        'quantidade': 302,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 1,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 135,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 4184,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Volta Redonda-RJ',
        'nomeCidadeOrigemComUF': 'Volta Redonda-RJ',
        'nomeCidadeOrigemComUFNormalizado': 'voltaredonda-rj',
        'valor': 249,
        'quantidade': 55,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2818,
        'ufDestino': 'SP',
        'nomeCidadeDestino': 'São Paulo-SP',
        'nomeCidadeDestinoComUF': 'São Paulo-SP*6ee8e111-adfc-4ee6-b5fd-fa5fe8580d43',
        'nomeCidadeDestinoComUFNormalizado': 'saopaulo-sp',
        'idCidadeDestino': 5848,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Ituiutaba-MG',
        'nomeCidadeOrigemComUF': 'Ituiutaba-MG',
        'nomeCidadeOrigemComUFNormalizado': 'ituiutaba-mg',
        'valor': 60,
        'quantidade': 32,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/6ee8e111-adfc-4ee6-b5fd-fa5fe8580d43'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 1,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Betim-MG',
        'nomeCidadeDestinoComUF': 'Betim-MG*',
        'nomeCidadeDestinoComUFNormalizado': 'betim-mg',
        'idCidadeDestino': 2504,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 189,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 5848,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Ituiutaba-MG',
        'nomeCidadeDestinoComUF': 'Ituiutaba-MG*',
        'nomeCidadeDestinoComUFNormalizado': 'ituiutaba-mg',
        'idCidadeDestino': 2818,
        'ufOrigem': 'SP',
        'nomeCidadeOrigem': 'São Paulo-SP',
        'nomeCidadeOrigemComUF': 'São Paulo-SP',
        'nomeCidadeOrigemComUFNormalizado': 'saopaulo-sp',
        'valor': 60,
        'quantidade': 12,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2498,
        'ufDestino': 'RJ',
        'nomeCidadeDestino': 'Angra dos Reis-RJ',
        'nomeCidadeDestinoComUF': 'Angra dos Reis-RJ*',
        'nomeCidadeDestinoComUFNormalizado': 'angradosreis-rj',
        'idCidadeDestino': 4093,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Belo Horizonte-MG',
        'nomeCidadeOrigemComUF': 'Belo Horizonte-MG',
        'nomeCidadeOrigemComUFNormalizado': 'belohorizonte-mg',
        'valor': 280,
        'quantidade': 11,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2504,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Betim-MG',
        'nomeCidadeOrigemComUF': 'Betim-MG',
        'nomeCidadeOrigemComUFNormalizado': 'betim-mg',
        'valor': 189,
        'quantidade': 10,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 4093,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Angra dos Reis-RJ',
        'nomeCidadeOrigemComUF': 'Angra dos Reis-RJ',
        'nomeCidadeOrigemComUFNormalizado': 'angradosreis-rj',
        'valor': 280,
        'quantidade': 8,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 1,
        'ufDestino': 'RJ',
        'nomeCidadeDestino': 'Angra dos Reis-RJ',
        'nomeCidadeDestinoComUF': 'Angra dos Reis-RJ*',
        'nomeCidadeDestinoComUFNormalizado': 'angradosreis-rj',
        'idCidadeDestino': 4093,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 300,
        'quantidade': 3,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2818,
        'ufDestino': 'SP',
        'nomeCidadeDestino': 'Botucatu-SP',
        'nomeCidadeDestinoComUF': 'Botucatu-SP*',
        'nomeCidadeDestinoComUFNormalizado': 'botucatu-sp',
        'idCidadeDestino': 5371,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Ituiutaba-MG',
        'nomeCidadeOrigemComUF': 'Ituiutaba-MG',
        'nomeCidadeOrigemComUFNormalizado': 'ituiutaba-mg',
        'valor': 149,
        'quantidade': 3,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/imagem-destino-padrao'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 3138,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Santos Dumont-MG',
        'nomeCidadeOrigemComUF': 'Santos Dumont-MG',
        'nomeCidadeOrigemComUFNormalizado': 'santosdumont-mg',
        'valor': 269,
        'quantidade': 2,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 4180,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Três Rios-RJ',
        'nomeCidadeOrigemComUF': 'Três Rios-RJ',
        'nomeCidadeOrigemComUFNormalizado': 'tresrios-rj',
        'valor': 229,
        'quantidade': 1,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 102,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte-MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte-MG*Belo horizonte mg vexado passagens-1341.webp',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeDestino': 2498,
        'ufOrigem': 'GO',
        'nomeCidadeOrigem': 'Cristalina-GO',
        'nomeCidadeOrigemComUF': 'Cristalina-GO',
        'nomeCidadeOrigemComUFNormalizado': 'cristalina-go',
        'valor': 50,
        'quantidade': 1,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2983,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Paracatu-MG',
        'nomeCidadeOrigemComUF': 'Paracatu-MG',
        'nomeCidadeOrigemComUFNormalizado': 'paracatu-mg',
        'valor': 119,
        'quantidade': 1,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 2843,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília-DF',
        'nomeCidadeDestinoComUF': 'Brasília-DF*Brasília-DF vexado passagens-5937.jpeg',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeDestino': 1,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'João Pinheiro-MG',
        'nomeCidadeOrigemComUF': 'João Pinheiro-MG',
        'nomeCidadeOrigemComUFNormalizado': 'joaopinheiro-mg',
        'valor': 169,
        'quantidade': 1,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Brasília-DF vexado passagens-5937.jpeg'
    }
]

export default dadosCardsDestinos;