import React, {Component} from 'react';
import BuscaPassagem from '../passagem/BuscaPassagem';
import {URL} from '../../util/ambienteActions';
import axios from 'axios';
import {SLOW_REQUEST_CONFIG} from '../../util/loadingUtil';
import {withRouter} from 'react-router-dom';
import {Card} from 'react-bootstrap';
import logoEmpSandbox from '../../estilo/comum/img/logo-03.png';
import {isAmbienteProd} from '../../util/applicationContext';
import {connect} from 'react-redux';
import {selecionarEmpresa} from '../../actions/empresaActions';
import imagemPadrao from '../../estilo/comum/img/ImagemTrechoPadrao.webp'

const ordemCidades = [
    'Rio-Branco-AC', 'Maceio-AL', 'Macapa-AP', 'Manaus-AM', 'Salvador-BA', 'Fortaleza-CE', 'Brasília-DF', 'Vitoria-ES', 'Goiania-GO', 'Sao-Luis-MA', 'Cuiaba-MT', 'Campo-Grande-MS', 'Belo-Horizonte-MG', 'Belem-PA', 'Joao-Pessoa-PB', 'Curitiba-PR', 'Recife-PE', 'Teresina-PI', 'Rio-de-Janeiro-RJ', 'Natal-RN', 'Porto-Alegre-RS', 'Boa-Vista-RR',
    'Florianopolis-SC', 'Sao-Paulo-SP', 'Aracaju-SE', 'Palmas-TO', 'Cruzeiro-do-Sul-AC', 'Arapiraca-AL', 'Santana-AP', 'Parintins-AM', 'Feira-de-Santana-BA', 'Caucaia-CE', 'Taguatinga-DF', 'Vila-Velha-ES', 'Aparecida-de-Goiania-GO', 'Imperatriz-MA', 'Varzea-Grande-MT', 'Dourados-MS', 'Uberlandia-MG', 'Ananindeua-PA', 'Campina-Grande-PB', 'Londrina-PR',
    'Jaboatao-dos-Guararapes-PE', 'Parnaiba-PI', 'Sao-Goncalo-RJ', 'Mossoro-RN', 'Caxias-do-Sul-RS', 'Rorainopolis-RR', 'Joinville-SC', 'Guarulhos-SP', 'Nossa-Senhora-do-Socorro-SE', 'Araguaina-TO', 'Tarauaca-AC', 'Palmeira-dos-Indios-AL', 'Laranjal-do-Jari-AP', 'Itacoatiara-AM', 'Vitoria-da-Conquista-BA', 'Juazeiro-do-Norte-CE', 'Serra-ES', 'Anapolis-GO',
    'Caxias-MA', 'Rondonopolis-MT', 'Tres-Lagoas-MS', 'Contagem-MG', 'Santarem-PA', 'Patos-PB', 'Maringa-PR', 'Olinda-PE', 'Picos-PI', 'Duque-de-Caxias-RJ', 'Parnamirim-RN', 'Pelotas-RS', 'Caracarai-RR', 'Blumenau-SC', 'Campinas-SP', 'Lagarto-SE', 'Gurupi-TO', 'Feijo-AC', 'Rio-Largo-AL', 'Oiapoque-AP', 'Manacapuru-AM', 'Itabuna-BA', 'Sobral-CE', 'Cariacica-ES',
    'Rio-Verde-GO', 'Timon-MA', 'Sinop-MT', 'Corumba-MS', 'Juiz-de-Fora-MG', 'Maraba-PA', 'Santa-Rita-PB', 'Ponta-Grossa-PR', 'Paulista-PE', 'Piripiri-PI', 'Nova-Iguacu-RJ', 'Sao-Jose-de-Mipibu-RN', 'Santa-Maria-RS', 'Alto-Alegre-RR', 'Sao-Jose-SC', 'Sao-Bernardo-do-Campo-SP', 'Itabaiana-SE', 'Porto-Nacional-TO'
];

export class PaginaTrechosEmpresa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topTrechos: [],
            activePage: 1,
            totalRegistros: 0,
            selecionarEmpresa: null
        };
    }

    buscarHorarios(idCidadeOrigem, idCidadeDestino) {
        axios.get(`${URL}/trecho/public/origem/${idCidadeOrigem}/destino/${idCidadeDestino}/nome-normalizado`, SLOW_REQUEST_CONFIG)
            .then(resp => {
                if (resp.data) {
                    const {nomeCidadeOrigemComUFNormalizado, nomeCidadeDestinoComUFNormalizado} = resp.data;
                    let url = `/passagem-de-onibus/${nomeCidadeOrigemComUFNormalizado}/${nomeCidadeDestinoComUFNormalizado}`;
                    this.props.history.push(url);
                }
            })
    }

    buscarTrechosPorEmpresa = (empresaId) => {
        axios.get(`${URL}/plataformas/public/trechos/empresa/${empresaId}`, SLOW_REQUEST_CONFIG)
            .then(respTrechos => {
                if (respTrechos && respTrechos.data && respTrechos.data.principaisTrechosDto) {
                    const {principaisTrechosDto} = respTrechos.data;
                    const destinosOrdenados = this.ordenarDestinos(principaisTrechosDto);
                    const topTrechos = destinosOrdenados.slice(0, 1000);


                    this.setState({ topTrechos });
                } else {
                    console.error('Dados da API (Trechos) não estão no formato esperado:', respTrechos.data);
                }
            })
            .catch(error => {
                console.error('Erro ao buscar dados da API de trechos:', error);
            });
    };

    buscarEmpresaPorNomeFantasia(nomeFantasia) {
        axios.get(`${URL}/empresa/public/nome-fantasia/${nomeFantasia}`, SLOW_REQUEST_CONFIG)
            .then(resp => {
                if (resp.data) {
                    const empresa = resp.data;
                    this.setState({empresa});
                    this.buscarTrechosPorEmpresa(empresa.empresaId);
                    this.props.selecionarEmpresa(empresa);
                } else {
                    console.error('Dados da API não estão no formato esperado:', resp.data);
                }
            }).catch(error => {
            console.error('Erro ao buscar dados da API:', error);
        });
    }

    ordenarDestinos(destinos) {
        const cidadesVistas = new Set();
        const cidadesPrioritarias = destinos.filter(destino =>
            ordemCidades.includes(destino.nomeCidadeDestinoComUF) &&
            !cidadesVistas.has(destino.nomeCidadeDestinoComUF) &&
            cidadesVistas.add(destino.nomeCidadeDestinoComUF)
        );
        const outrasCidades = destinos.filter(destino =>
            !ordemCidades.includes(destino.nomeCidadeDestinoComUF)
        );
        const destinosOrdenados = [...cidadesPrioritarias, ...outrasCidades];

        return destinosOrdenados.slice(0, destinos.length);
    }

    componentDidMount() {
        const {match: {params}, selecionada} = this.props;
        this.setState({selecionada: selecionada});
        if (params.empresaId) {
            const nomeFantasia = params.empresaId;
            this.buscarEmpresaPorNomeFantasia(nomeFantasia)
        }
    }

    render() {
        const { topTrechos } = this.state;
        return (
            <div className='conteudo'>
                <div>
                    <BuscaPassagem/>
                </div>
                <div className='page-contato container mt-5'>
                    <h2 id='secao-trechos' className="gv-title">Buscando trechos na empresa:</h2>
                    <Card className='card-Empresa'>
                        <Card.Body className='card-body-empresa'>
                            {this.state.empresa &&
                                <Card.Img className='card-img-empresa' alt='Logo Empresa' variant="top"
                                          src={isAmbienteProd() ? this.state.empresa.logomarca : logoEmpSandbox}/>
                            }
                        </Card.Body>
                    </Card>
                </div>
                <div className='page-contato container mt-5'>
                    <h2 id='secao-trechos' className="gv-title">Confira as rotas que estamos operando:</h2>
                    <div className='container-trechos'>
                        {topTrechos.map((l, index) => (
                            <div className='card-principaisTrechos' key={index}>
                                {index < 1000 &&
                                    <div className='card p-3'>
                                        <div className='card-trecho'>
                                            <Card.Img variant="top" src={l.urlImagem ? l.urlImagem : imagemPadrao} alt="Imagem da Rota"/>
                                            <div className='box-icon mt-3'>
                                                <div className='icon-origem'></div>
                                                <span className="material-icons icon-origem-2" style={{color:'red'}}>place</span>
                                                <h3 id='nomeTrechoOrigem' className='ml-3'>{l.nomeCidadeOrigemComUF}</h3>
                                                <h3 id='nomeTrechoDestino' className='ml-3 mt-2'>{l.nomeCidadeDestinoComUF}</h3>
                                            </div>
                                            <div id='buttonHorarios' className='line-btn'>
                                                <button onClick={() => this.buscarHorarios(
                                                    l.idCidadeOrigem,
                                                    l.idCidadeDestino
                                                )}
                                                    id='buttonHorarios' className="btn botaoLogar btn-buscaInterna btn-lista-trechos">
                                                    Horários
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = state => (
    {
        selecionada: state.empresa.selecionada,
    }
)

export default withRouter(connect(mapStateToProps, {selecionarEmpresa})(PaginaTrechosEmpresa))
