import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {recuperarConfEmpresa} from '../../../util/applicationContext';
import dadosGaleriaImagens from './dadosGaleriaImagens';

export class GaleriaImagensVexado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [...dadosGaleriaImagens],
            currentPage: 1,
            linksPerPage: 100,
            filterOrigem: '',
            filterDestino: '',
            maxPageNumbers: 10,
            showModal: false,
            selectedImage: null,
        };
    }

    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id),
        });
    };

    handleNext = () => {
        this.setState((prevState) => ({
            currentPage: Math.min(prevState.currentPage + 1, Math.ceil(this.state.links.length / this.state.linksPerPage)),
        }));
    };

    handlePrev = () => {
        this.setState((prevState) => ({
            currentPage: Math.max(prevState.currentPage - 1, 1),
        }));
    };

    handleFirst = () => {
        this.setState({
            currentPage: 1,
        });
    };

    handleLast = () => {
        this.setState({
            currentPage: Math.ceil(this.state.links.length / this.state.linksPerPage),
        });
    };

    openModal = (imageLink) => {
        this.setState({
            showModal: true,
            selectedImage: imageLink,
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            selectedImage: null,
        });
    };

    render() {
        const {links, currentPage, linksPerPage, maxPageNumbers, showModal, selectedImage} = this.state;

        const indexOfLastLink = currentPage * linksPerPage;
        const indexOfFirstLink = indexOfLastLink - linksPerPage;
        const currentLinks = links.slice(indexOfFirstLink, indexOfLastLink);

        const totalPages = Math.ceil(links.length / linksPerPage);

        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
        let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

        if (endPage - startPage < maxPageNumbers - 1) {
            startPage = Math.max(1, endPage - maxPageNumbers + 1);
        }

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={this.handleClick}
                    className={number === currentPage ? 'active' : ''}
                    style={{display: 'flex', justifyContent: 'center', cursor: 'pointer', padding: '0.5rem'}}
                >
                    {number}
                </li>
            );
        });

        return (
            <div id='container-topTrechos1' className='container-toptrechos'>
                <div id='containerTituloTrechos' className='titulo-topTrechos'>
                    <span>
                        <b id='tituloTrechos'> Galeria de Imagens {recuperarConfEmpresa().nome} </b>
                    </span>
                    <span id='subTituloTopTrechos'>
                        <b id='subTituloTrechos'> Confira as cidades mais procuradas em nossa central de passagens </b>
                    </span>
                </div>

                <div className="links-e-paginacao">
                    <ul id="page-numbers-top" className="page-numbers">
                        <li onClick={this.handleFirst} style={{cursor: 'pointer', padding: '0.5rem'}}>Primeira</li>
                        <li onClick={this.handlePrev} style={{cursor: 'pointer', padding: '0.5rem'}}>Anterior</li>
                        {renderPageNumbers}
                        <li onClick={this.handleNext} style={{cursor: 'pointer', padding: '0.5rem'}}>Próxima</li>
                        <li onClick={this.handleLast} style={{cursor: 'pointer', padding: '0.5rem'}}>Última</li>
                    </ul>

                    <div className='container-grouped-imagens'>
                        <div className="image-grid">
                            {currentLinks.map((item, index) => (
                                <div key={index} className="image-item" onClick={() => this.openModal(item.link)} cursor='pointer'>
                                    <img src={item.link} alt={`imagem-${index}`} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <ul id="page-numbers-bottom" className="page-numbers">
                        <li onClick={this.handleFirst} style={{cursor: 'pointer', padding: '0.5rem'}}>Primeira</li>
                        <li onClick={this.handlePrev} style={{cursor: 'pointer', padding: '0.5rem'}}>Anterior</li>
                        {renderPageNumbers}
                        <li onClick={this.handleNext} style={{cursor: 'pointer', padding: '0.5rem'}}>Próxima</li>
                        <li onClick={this.handleLast} style={{cursor: 'pointer', padding: '0.5rem'}}>Última</li>
                    </ul>
                </div>

                {showModal && (
                    <div className="modal-galeria" onClick={this.closeModal}>
                        <span className="close">&times;</span>
                        <img className="modal-galeria-content" src={selectedImage} alt="Imagem ampliada" />
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(GaleriaImagensVexado);