import React from 'react'
import QRCode from 'qrcode.react';
import { formatarReal, completarComZerosAEsquerda } from '../../util/numberUtil';
import { showErrorMsg } from '../../util/messagesUtil';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import moment from 'moment'

export class PaginaReciboReserva extends React.Component {
    constructor(props) {
        super(props);

        const { reservaSelecionada } = this.props;
        if (reservaSelecionada) {
            if (reservaSelecionada.dataHoraEmbarque !== null) {
                showErrorMsg('Reserva embarcada.');
                this.props.history.push('/minhas-reservas')
            }
        } else {
            this.props.history.push('/minhas-reservas')
        }
        this.state ={voucher:null}

        this.componentRef = React.createRef();
    }

    exibirDadosRota(reserva) {
        return <React.Fragment>
            {reserva.idRemarcada ?
                <tr className="top">
                    <td><b>**REMARCAÇÃO</b></td>
                </tr> : null
            }
            <tr className="top">
                <td colSpan="3">Pedido: {reserva.pedidoId}</td>
            </tr>
            <tr>
                <td colSpan="3">Rota: {reserva.rota}</td>
            </tr>
            <tr>
                <td colSpan="3">Prefixo: {reserva.prefixoRota ? reserva.prefixoRota : 'Não informado'}</td>
            </tr>
            <tr>
                <td colSpan="3">Origem: {reserva.descCidadeOrigemAlternativa ? reserva.descCidadeOrigemAlternativa : reserva.descCidadeOrigem}</td>
            </tr>
            <tr>
                <td colSpan="3">Destino: {reserva.descCidadeDestinoAlternativa ? reserva.descCidadeDestinoAlternativa : reserva.descCidadeDestino}</td>
            </tr>
            <tr>
                <td colSpan="3">Data/Hora embarque: {reserva.dataHoraPartida}</td>
            </tr>
            <tr>
                <td colSpan="3">Tipo de Viagem: {reserva.descricaoTipoPreco}</td>
            </tr>
            <tr>
                <td colSpan="3">
                    <b>Plataforma embarque: </b>{reserva.plataformaEmbarque ? reserva.plataformaEmbarque : '' }
                </td>
            </tr>
        </React.Fragment>
    }
    exibirDadosRotaIntegracao(reserva) {
        const {voucher} = this.props
        return <React.Fragment>
            {reserva.idRemarcada ?
                <tr className="top">
                    <td><b>**REMARCAÇÃO</b></td>
                </tr> : null
            }
            <tr className="top">
                <td colSpan="3">Pedido: {reserva.pedidoId}</td>
            </tr>
            <tr>
                <td colSpan="3">Rota: {voucher.rota}</td>
            </tr>
            <tr>
                <td colSpan="3">Prefixo: {voucher.prefixo ? voucher.prefixo : 'Não informado'}</td>
            </tr>
            <tr>
                <td colSpan="3">Origem: {reserva.descCidadeOrigemAlternativa ? reserva.descCidadeOrigemAlternativa : reserva.descCidadeOrigem}</td>
            </tr>
            <tr>
                <td colSpan="3">Destino: {reserva.descCidadeDestinoAlternativa ? reserva.descCidadeDestinoAlternativa : reserva.descCidadeDestino}</td>
            </tr>
            <tr>
                <td colSpan="3">Data/Hora embarque: {reserva.dataHoraPartida}</td>
            </tr>
            <tr>
                <td colSpan="3">Tipo de Viagem: {reserva.descricaoTipoPreco}</td>
            </tr>
        </React.Fragment>
    }

    exibirPassageiro(reserva, exibeCrianca) {
        return <React.Fragment key={reserva.passageiroDto.id}>
            <tr className="top">
                <td colSpan="3">Número do bilhete: {reserva.id}</td>
            </tr>
            <tr>
                <td colSpan="3">Passageiro: {reserva.passageiroDto.nome}</td>
            </tr>
            <tr>
                <td colSpan="3">Poltrona: {reserva.numeroPoltrona}</td>
            </tr>
            <tr>
                <td colSpan="3">Tipo emissão: {reserva.passageiroDto.descTipoEmissao}</td>
            </tr>
            <tr>
                <td colSpan="3">CPF: {reserva.passageiroDto.cpfPassageiro}</td>
            </tr>
            <tr>
                <td colSpan="3">Doc. embarque: {reserva.passageiroDto.documentoComFoto}</td>
            </tr>
            {!exibeCrianca
                ? this.exibirDadosCrianca(reserva.passageiroDto) : null}
        </React.Fragment>
    }

    exibirCriacaColo(passageiro) {
        if (passageiro.criancaDto) {
            return <React.Fragment>
                {this.exibirDadosCrianca(passageiro)}
                <tr align="center">
                    <td colSpan="3">
                        <QRCode style={{
                            width: 80,
                            height: 80,
                        }}
                            value={`${passageiro.criancaDto.id}`} />
                    </td>
                </tr>
            </React.Fragment>
        }
        return null
    }

    exibirDadosCrianca(passageiro) {
        const crianca = passageiro.criancaDto;
        if (crianca) {
            return <React.Fragment>
                <tr className='top'>
                    <td colSpan="3">Criança de colo: {crianca.nome}</td>
                </tr>
                <tr>
                    <td colSpan="3">Poltrona: {passageiro.numeroPoltrona}</td>
                </tr>
                <tr>
                    <td colSpan="3">Data nascimento criança: {crianca.dtNascimentoCrianca}</td>
                </tr>
                <tr>
                    <td colSpan="3">Doc. embarque criança: {crianca.documentoCriacaComFoto}</td>
                </tr>
                {crianca.cpfCrianca
                    ? <tr>
                        <td colSpan="3">CPF criança: {crianca.cpfCrianca}</td>
                    </tr> : null}
            </React.Fragment>
        }
    }

    exibirBotaoImprimir() {
        return (
            <React.Fragment>
                <ReactToPrint
                    trigger={() => <button className="btn btn-laranja">Imprimir
                    </button>}
                    content={() => this.componentRef.current}
                />
            </React.Fragment>)
    }

    exibirCabecalho(label, reserva, seCrianca) {
        return <React.Fragment>
            <tr>
                <th className="title" colSpan="3">
                    {reserva.nomeEmpresa ? reserva.nomeEmpresa : ''}
                    <br />
                    {_.isNil(reserva.cnpjEmpresa) ?
                        <React.Fragment><strong>CNPJ: </strong><span>{reserva.cnpjEmpresa}</span></React.Fragment> : ''}
                    {_.isNil(reserva.endereco) ? '' :
                        <span className='text-center'>{`
            ${reserva.endereco.logradouro ? reserva.endereco.logradouro : ''}
            ${reserva.endereco.numero ? reserva.endereco.numero : ''}
            ${reserva.endereco.complemento ? `, ${reserva.endereco.complemento}` : ''}
             ${reserva.endereco.bairro ? reserva.endereco.bairro : ''}
             ${reserva.endereco.localidade ? '-' + reserva.endereco.localidade : ''}
             ${reserva.endereco.uf ? '-' + reserva.endereco.uf : ''}
             `},<strong> CEP:</strong>{reserva.endereco.cep ? reserva.endereco.cep : ''}
                        </span>
                    }
                </th>
            </tr>
            <tr>
                <th colSpan="3">{reserva.dataVenda}</th>
            </tr>
            <tr>
                {seCrianca ? <th colSpan="3">
                    {reserva.passageiroDto.criancaDto.nome} <br />
                    {reserva.passageiroDto.criancaDto.cpfCrianca}
                </th> :
                    <th colSpan="3">
                        {reserva.passageiroDto.nome} <br />
                        {reserva.passageiroDto.cpf}
                    </th>}
            </tr>
            <tr>
                <th className="ttu" colSpan="3">
                    <b>{label}</b>
                </th>
            </tr>
        </React.Fragment>
    }

    exibirQrCodeVexado(reserva, via) {
        if (via === 'Cupom de embarque') {
            return <React.Fragment>
                <tr align="center">
                    <td colSpan="3">
                        <QRCode style={{
                            width: 160,
                            height: 160,
                        }}
                                value={this.getValorQRCode(reserva)}/>
                    </td>
                </tr>
            </React.Fragment>
        }

    }

    getValorQRCode(reserva) {
        const prefixoRota = reserva.prefixoRota.replace(/[^\d]+/g, '') ?
            reserva.prefixoRota.replace(/[^\d]+/g, '').substr(0, 8) : '00000000';
        const dataMoment = moment.utc(reserva.dataHoraPartida, 'DD-MM-YYYY HH:mm');
        const numeroEquipamento = reserva.transmissaoBpe && reserva.transmissaoBpe.chaveAcesso ? completarComZerosAEsquerda(reserva.transmissaoBpe.chaveAcesso, 44) : '00000000000000000000000000000000000000000000';
        const numeroBilheteEmbarque =  completarComZerosAEsquerda(`${reserva.id}`, 9);
        const identificacaoLinha =  completarComZerosAEsquerda(prefixoRota, 8);
        const dataPartida = completarComZerosAEsquerda(dataMoment.format('YYYYMMDD'), 8);
        const horaPartida = completarComZerosAEsquerda(dataMoment.format('HHmmss'), 6);
        const codigoDesconto = '00';
        const valorTarifa = '0000000000';
        const percentualDesconto = '00000';
        const celularPassageiro = '00000000000000';
        const origem = completarComZerosAEsquerda(reserva.codigoAnttCidadeOrigem, 6);
        const destino = completarComZerosAEsquerda(reserva.codigoAnttCidadeDestino, 6);
        return `${numeroEquipamento}${numeroBilheteEmbarque}${identificacaoLinha}${dataPartida}${horaPartida}${codigoDesconto}${valorTarifa}${percentualDesconto}${celularPassageiro}${origem}${destino}`;
    }

    // exibirVoucher(reserva, crianca){
    //     const {voucher} = this.state
    //     if(!voucher)
    //         return '';
    //     return <React.Fragment>
    //         <tbody>
    //         {this.exibirDadosRotaIntegracao(reserva)}
    //         </tbody>
    //         <tfoot>
    //         {this.exibirTotaisReserva(reserva, crianca)}
    //         </tfoot>
    //     </React.Fragment>
    // }

    exibirVoucher(reserva) {
        const {voucher} = this.props
        if(!voucher) return ''

        return <div>
            <table className="printer-ticket">
                <thead>
                {this.exibirCabecalho('Dados do pagamento', reserva, false)}
                </thead>
                <tbody>
                {this.exibirDadosRotaIntegracao(reserva)}
                {this.exibirPassageiro(reserva, false)}
                </tbody>
                {this.exibirTotaisReserva(reserva, false)}
            </table>
            {this.linha()}
        </div>
    }

    exibirInformacaoCupom(via, reserva, crianca){
        return <React.Fragment>
            <tbody>
            {this.exibirQrCodeVexado(reserva, via)}
            {this.exibirDadosRota(reserva)}
            </tbody>
            <tfoot>
            {this.exibirTotaisReserva(reserva, crianca)}
            {this.exibirBpe(reserva, via)}
            </tfoot>
        </React.Fragment>
    }
    // exibirCupom(via, reserva, crianca) {
    //     return <div>
    //         <table className="printer-ticket">
    //             <thead>
    //             {!crianca ? this.exibirCabecalho(via, reserva)
    //                 : this.exibirCabecalho(via, reserva, true)}
    //             </thead>
    //             <tbody>
    //             {this.exibirQrCodeVexado(reserva, via)}
    //             {this.exibirDadosRota(reserva)}
    //             {!crianca ? this.exibirPassageiro(reserva, true) : this.exibirCriacaColo(reserva.passageiroDto)}
    //             </tbody>
    //             <tfoot>
    //             {this.exibirTotaisReserva(reserva, crianca)}
    //
    //             {this.exibirBpe(reserva, via)}
    //             </tfoot>
    //         </table>
    //         {this.linha()}
    //     </div>
    // }
    exibirCupom(via, reserva, crianca) {
        if( reserva.ehItegracao)
            return (
                <div>
                    <table className="printer-ticket">
                        {this.exibirVoucher(reserva, crianca)}
                    </table>
                    {this.linha()}
                </div>
            );
        return <div>
            <table className="printer-ticket">
                <thead>
                    {!crianca ? this.exibirCabecalho(via, reserva)
                        : this.exibirCabecalho(via, reserva, true)}
                </thead>
                { this.exibirInformacaoCupom(via, reserva, crianca)}

            </table>
            {this.linha()}
        </div>
    }

    exibirBilheteEmbarque(via, reserva) {
        return (
            <div>
                {this.exibirLimiteCupom()}
                <table className="printer-ticket">
                    <thead>
                        {this.exibirCabecalhoBilheteEmbarque(via, reserva)}
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="3">Empresa: {reserva.nomeEmpresa} - {reserva.dataVenda}</td>
                        </tr>
                        <tr>
                            <td  colSpan="3">Origem: {reserva.descCidadeOrigemAlternativa ? reserva.descCidadeOrigemAlternativa : reserva.descCidadeOrigem}
                            /Destino: {reserva.descCidadeDestinoAlternativa ? reserva.descCidadeDestinoAlternativa : reserva.descCidadeDestino}
                            /Bilhete: {reserva.id}</td>
                        </tr>
                        <tr>
                            <td  colSpan="3">Data/Hora Embarque: {reserva.dataHoraPartida}</td>
                        </tr>
                        <tr>
                            <td  colSpan="3">Número tarifa: {reserva.numeroTarifa} / Código tarifa: {reserva.codigoTarifa} </td>
                        </tr>
                        <tr>
                            <td colSpan="3">Valor: {formatarReal(reserva.taxaEmbarque ? reserva.taxaEmbarque.toString() : '0')}</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr align="center">
                            <td  colSpan="3" style={{marginTop: '10px'}}>
                                <QRCode style={{
                                        width: 160,
                                        height: 160
                                    }}
                                    value={reserva.numeroTarifa}
                                />
                            </td>
                        </tr>
                    </tbody>                    
                </table>
                {this.exibirLimiteCupom()}
                {this.linha()}
            </div>
        )
    }

    exibirCabecalhoBilheteEmbarque(via) {
        return (
            <>
               <tr>
                    <th colSpan="3" style={{textTransform: 'uppercase'}}>{via}</th>
                </tr>             
            </>
        )
    }

    exibirICMS(reserva) {
        if (reserva.icmsDesconto || reserva.aliquotaICMS) {
            return <React.Fragment>ICMS: {reserva.icmsDesconto ? reserva.icmsDesconto.replace('.', ',')
                : reserva.icmsDesconto} {reserva.aliquotaICMS ? (reserva.aliquotaICMS) : ''}</React.Fragment>
        }

    }

    exibirTributos(reserva) {
        if (reserva.outrosDescontos || reserva.outrosTributos) {
            return <React.Fragment>TRIB: {reserva.outrosDescontos ? reserva.outrosDescontos.replace('.', ',')
                : reserva.outrosDescontos} ({reserva.outrosTributos ? reserva.outrosTributos.replace('.', ',')
                    : reserva.outrosTributos}%)</React.Fragment>
        }

    }

    exibirBpe(reserva, via) {
        if (via === 'Cupom de embarque') {
            return null
        }
        if (reserva.transmissaoBpe) {
            return <React.Fragment>
                <tr className="sup">
                    <td colSpan="3" align="center">
                        {this.exibirICMS(reserva)}
                        {this.exibirTributos(reserva)}
                    </td>
                </tr>
                <tr className="ttu">
                    <td colSpan="3" align="center" className="font-sm">
                        (Lei Federal 12.741/2012)
                    </td>
                </tr>
                <br />
                <tr className="ttu">
                    <td colSpan="3" align="center" className="font-sm" style={{ wordWrap: 'break-word' }}>
                        {reserva.transmissaoBpe.urlConsulta}
                    </td>
                </tr>
                <tr className="ttu">
                    <td colSpan="3" align="center" className="font-sm">
                        {reserva.transmissaoBpe.chaveAcesso}
                    </td>
                </tr>
                {reserva.transmissaoBpe.emitidoEmContigencia ? <tr align="center">
                    <td colSpan="3">
                        <QRCode style={{
                            width: 80,
                            height: 80,
                        }}
                            value='http://bpeemitidoemcontingencia.log.br/' />
                    </td>
                </tr> : <tr align="center">
                    <td colSpan="3">
                        <QRCode style={{
                            width: 80,
                            height: 80,
                        }}
                            value={`${reserva.transmissaoBpe.qrCod}`} />
                    </td>
                </tr>}
                {reserva.transmissaoBpe.emitidoEmContigencia ? <tr align="center">
                    <td colSpan="3">
                        <span style={{ color: 'red' }}>EMITIDO EM CONTINGÊNCIA</span>
                    </td>
                </tr> : null}
            </React.Fragment>
        }
    }

    calcularValorTarifa({ tarifa, desconto }) {
        return Number(tarifa) + Number(desconto)
    }

    calcularValorPago({ taxaServico, valorTotalReserva }) {
        return Number(valorTotalReserva) + Number(taxaServico)
    }

    exibirTotaisReserva(reserva, crianca) {
        return <React.Fragment>{!crianca ? <React.Fragment>
            <tr className="sup ttu p--0">
                <td colSpan="3">
                    <b>Totais</b>
                </td>
            </tr>
            <tr className="ttu">
                <td colSpan="2">Tarifa</td>
                <td align="right">{formatarReal(this.calcularValorTarifa(reserva)
                    .toString())}
                </td>
            </tr>
            <tr className="ttu">
                <td colSpan="2">Taxa de embarque</td>
                <td align="right">{formatarReal(reserva.taxaEmbarque ? reserva.taxaEmbarque.toString() : '0')}</td>
            </tr>
            <tr className="ttu">
                <td colSpan="2">Pedágio</td>
                <td align="right">{formatarReal(reserva.pedagio ? reserva.pedagio.toString() : '0')}</td>
            </tr>
            <tr className="ttu">
                <td colSpan="2">Taxa de serviço</td>
                <td align="right">{formatarReal(reserva.taxaServico ? reserva.taxaServico.toString() : '0')}</td>
            </tr>
            <tr className="ttu">
                <td colSpan="2">Desconto</td>
                <td align="right">{reserva.desconto ? formatarReal(reserva.desconto.toString()) : formatarReal('0')}</td>
            </tr>
            <tr className="ttu">
                <td colSpan="2">Valor total pago</td>
                <td align="right">{formatarReal(this.calcularValorPago(reserva).toString())}</td>
            </tr>
            <tr className="ttu">
                <td colSpan="2">Forma de pagamento</td>
                <td align="right">{reserva.descricaoFormasPagamento}</td>
            </tr>
        </React.Fragment> : null}
        </React.Fragment>

    }


    linha() {
        return <hr id='printPageButton' style={{
            borderStyle: 'dashed',
            borderWidth: 3,
        }} />
    }

    exibirCupomCancelamento(reserva) {
        return <div>
            <table className="printer-ticket">
                <thead>
                    {this.exibirCabecalho('Recibo de Cancelamento', reserva, false)}
                </thead>
                <tbody>
                    {this.exibirDadosRota(reserva)}
                    {this.exibirPassageiro(reserva, false)}
                </tbody>
                {this.exibirTotaisReserva(reserva, false)}
            </table>
            {this.linha()}
        </div>
    }

    exibirLimiteCupom() {
        return <div className='printer-element'>--</div>
    }

    exibirDireitoDeveres() {
        return (
            <div>
                {this.exibirLimiteCupom()}
                <div className="printer-ticket" style={{textAlign: 'justify', padding: '5px'}}>
                    <h2>DIREITOS DOS PASSAGEIROS</h2>
                    <p>I - ser transportado com pontualidade, segurança, higiene e conforto;</p>
                    <p>II - Transportar, gratuitamente, até 30 (trinta) quilos de bagagem no bagageiro e 5 (cinco) quilos
                        de volume no porta-embrulho;</p>
                    <p>III - receber os comprovantes das bagagens transportadas no bagageiro e ser indenizado por
                        extravio ou dano de bagagem transportada no bagageiro;</p>
                    <p>IV - Receber a diferença do preço da passagem em veículos de características inferiores às
                        daquele contratado;</p>
                    <p>V - Receber, às expensas da transportadora, alimentação e pousada, nos casos de venda de
                        mais de um bilhete de passagem para a mesma poltrona ou interrupção/retardamento da
                        viagem, após 3 (três) horas, em razão de defeito, falha ou outro motivo de responsabilidade da
                        transportadora;</p>
                    <p></p>VI - Receber da transportadora, em caso de acidente, imediata e adequada assistência;
                    <p>VII - optar, em caso de atraso por período superior a 1 (uma) hora, por: continuar a viagem em
                        outra empresa às expensas da transportadora; ou receber de imed iato o valor do bilhete de
                        passagem, em caso de desistência; ou continuar a viagem, pela mesma transportadora, que
                        deverá dar continuidade à viagem num período máximo de 3 (três) horas após a interrupção;</p>
                    <p>VIII - remarcar o bilhete adquirido observado o prazo de um 1 (ano) de validade do bilhete a
                        contar da data da primeira emissão. A partir de 3 (três) horas antes do início da viagem, é
                        facultado à transportadora efetuar a cobrança de até 20% (vinte por cento) do valor da tarifa a
                        título de remarcação.</p>
                    <p>IX - Transferir o bilhete adquirido, observado o prazo de 1 (um) ano de validade do bilhete a
                        contar da data da primeira emissão.</p>
                    <p>X - Receber a importância paga no caso de desistência da viagem, desde que com
                        antecedência mínima de 3 (três) horas em relação ao horário de partida constante do bilhete,
                        facultado à transportadora o desconto de 5% (cinco por cento) do valor da tarifa;</p>
                    <p>XI - estar garantido pelo Seguro de Responsabilidade Civil contratado pela transportadora;</p>
                    <p>XII - não ser obrigado a adquirir seguro facultativo complementar de viagem.</p>
                </div>
                {this.exibirLimiteCupom()}
                {this.linha()}
            </div>
        )
    }

    exibirRecibo() {
        const { reservaSelecionada } = this.props;
        return <div className='col-md-12 d-flex justify-content-center'>
            <div>
                <div className="pagebreak" />
                {this.linha()}
                <div ref={this.componentRef}>
                    <div>
                        {reservaSelecionada.situacaoReserva === 'CANCELADO' ? this.exibirCupomCancelamento(reservaSelecionada) :
                            <div>
                                {reservaSelecionada.ehItegracao ?
                                        <div>
                                            {this.exibirVoucher(reservaSelecionada)}
                                        </div>
                                        :
                                        <div>
                                            {this.exibirCupom('Via do passageiro', reservaSelecionada)}
                                            <div className="pagebreak" />
                                            {this.exibirDireitoDeveres()}
                                            <div className="pagebreak" />
                                            {this.exibirCupom('Cupom de embarque', reservaSelecionada)}
                                            {reservaSelecionada.regraBilhete == 'REGRA_W2I' && <><div className="pagebreak"/>
                                                {this.exibirBilheteEmbarque('Bilhete de Embarque', reservaSelecionada)}</>}
                                        </div>
                                }
                            </div>
                        }
                        {reservaSelecionada.passageiroDto.criancaDto && reservaSelecionada.situacaoReserva !== 'CANCELADO'
                            ? <div>
                                {this.exibirCupom('Via do passageiro', reservaSelecionada, true)}
                                <div className="pagebreak" />
                                {this.exibirDireitoDeveres()}
                                <div className="pagebreak" />
                                {this.exibirCupom('Cupom de embarque', reservaSelecionada, true)}
                            </div> : null}
                    </div>
                </div>
            </div>
        </div>
    }

    cabecalhoRodapePagina() {
        return <div className='col-md-12 d-flex justify-content-center mb-2'>
            {this.exibirBotaoImprimir()}
            <button className="btn btn-laranja ml-4" onClick={() => this.props.history.push('minhas-reservas')}>Voltar
            </button>
        </div>
    }

    render() {
        const { reservaSelecionada } = this.props;
        if (reservaSelecionada) {
            return (
                <React.Fragment>
                    {this.cabecalhoRodapePagina()}
                    {this.exibirRecibo()}
                    {this.cabecalhoRodapePagina()}
                </React.Fragment>
            )
        }
        return ''
    }

}

export const mapStateToProps = state => (
    {
        reservaSelecionada: state.reserva.reservaSelecionada,
        voucher: state.pedido.voucher
    });

export default connect(mapStateToProps, {})(PaginaReciboReserva)
