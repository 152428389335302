const topTrechos = [
    {
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        nomeCidadeOrigemComUFNormalizado: 'belohorizonte-mg',
        nomeCidadeDestinoComUF: 'Brasília-DF',
        nomeCidadeDestinoComUFNormalizado: 'brasilia-df',
        ordem: 1
    },
    {
        nomeCidadeOrigemComUF: 'São Paulo-SP',
        nomeCidadeOrigemComUFNormalizado: 'saopaulo-sp',
        nomeCidadeDestinoComUF: 'Brasília-DF',
        nomeCidadeDestinoComUFNormalizado: 'brasilia-df',
        ordem: 2
    },
    {
        nomeCidadeOrigemComUF: 'São Paulo-SP',
        nomeCidadeOrigemComUFNormalizado: 'saopaulo-sp',
        nomeCidadeDestinoComUF: 'Belo Horizonte-MG',
        nomeCidadeDestinoComUFNormalizado: 'belohorizonte-mg',
        ordem: 3
    },
    {
        nomeCidadeOrigemComUF: 'Rio de Janeiro-RJ',
        nomeCidadeOrigemComUFNormalizado: 'riodejaneiro-rj',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 4
    },
    {
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 5
    },
    {
        nomeCidadeOrigemComUF: 'Curitiba-PR',
        nomeCidadeOrigemComUFNormalizado: 'curitiba-pr',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 6
    },
    {
        nomeCidadeOrigemComUF: 'Salvador-BA',
        nomeCidadeOrigemComUFNormalizado: 'salvador-ba',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 7
    },
    {
        nomeCidadeOrigemComUF: 'Fortaleza-CE',
        nomeCidadeOrigemComUFNormalizado: 'fortaleza-ce',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 8
    },
    {
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        nomeCidadeOrigemComUFNormalizado: 'belohorizonte-mg',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 9
    },
    {
        nomeCidadeOrigemComUF: 'Recife-PE',
        nomeCidadeOrigemComUFNormalizado: 'recife-pe',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 10
    },
    {
        nomeCidadeOrigemComUF: 'Porto Alegre-RS',
        nomeCidadeOrigemComUFNormalizado: 'portoalegre-rs',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 11
    },
    {
        nomeCidadeOrigemComUF: 'Manaus-AM',
        nomeCidadeOrigemComUFNormalizado: 'manaus-am',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 12
    },
    {
        nomeCidadeOrigemComUF: 'Goiânia-GO',
        nomeCidadeOrigemComUFNormalizado: 'goiania-go',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 13
    },
    {
        nomeCidadeOrigemComUF: 'Florianópolis-SC',
        nomeCidadeOrigemComUFNormalizado: 'florianopolis-sc',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 14
    },
    {
        nomeCidadeOrigemComUF: 'Belém-PA',
        nomeCidadeOrigemComUFNormalizado: 'belem-pa',
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 15
    }
]

export default topTrechos;