import React, { Component } from 'react';
import axios from 'axios';
import { URL } from '../../util/ambienteActions';
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil';
import { withRouter } from 'react-router-dom';
import { recuperarConfEmpresa } from '../../util/applicationContext';

export class CardsTopTrechos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupedTrechos: [],
        };
    }

    buscarHorarios(idCidadeOrigem, idCidadeDestino) {
        axios.get(`${URL}/trecho/public/origem/${idCidadeOrigem}/destino/${idCidadeDestino}/nome-normalizado`, SLOW_REQUEST_CONFIG)
            .then(resp => {
                if (resp.data) {
                    const { nomeCidadeOrigemComUFNormalizado, nomeCidadeDestinoComUFNormalizado } = resp.data;
                    let url = `/passagem-de-onibus/${nomeCidadeOrigemComUFNormalizado}/${nomeCidadeDestinoComUFNormalizado}`;
                    this.props.history.push(url);
                }
            })
    }

    componentDidMount() {
        axios.get(`${URL}/plataformas/public/trechos`, SLOW_REQUEST_CONFIG).then(resp => {
            if (resp.data) {
                const { principaisTrechosDto } = resp.data;
                const topTrechos = principaisTrechosDto.slice(0, 15);

                const groupedTrechos = [];
                while (topTrechos.length > 0) {
                    groupedTrechos.push(topTrechos.splice(0, 5));
                }

                this.setState({ groupedTrechos });
            }
        });
    }

    render() {
        const { groupedTrechos } = this.state;

        return (
            <div id='container-topTrechos1' className='container-toptrechos'>
                <div id='containerTituloTrechos' className='titulo-topTrechos'>
                    <span>
                        <b id='tituloTrechos'> Top Trechos {recuperarConfEmpresa().nome} </b>
                    </span>
                    <span id='subTituloTopTrechos'>
                        <b id='subTituloTrechos'> Os trechos mais procurados em nossa central de passagens </b>
                    </span>
                </div>
                <div className='container-groupedTrechos'>
                    {groupedTrechos.map((group, groupIndex) => (
                        <div key={groupIndex} id={`containertopTrechos${groupIndex}`} className='containertopTrechos'>
                            {group.map((trecho, index) => (
                                <div className='col-md-4 mb-4' key={index}>
                                    <div className='card-topTrechos'>
                                        <a id='topTrechosLink' onClick={() => this.buscarHorarios(trecho.idCidadeOrigem, trecho.idCidadeDestino)}>
                                            <div className='cardTrecho'>
                                                <div className='container-origemDestino'>
                                                    <div className='icon-topOrigem'>
                                                        <span id='destinoIcons' className="fa fa-map-marker"></span>
                                                        <h3 className='trechoOrigem'>{trecho.nomeCidadeOrigemComUF}</h3>
                                                    </div>
                                                    <div className='icon-topDestino'>
                                                        <span id='arrowTrechos' className='fas fa-arrow-right'></span>
                                                        <h3 className='trechoDestino'>{trecho.nomeCidadeDestinoComUF}</h3>
                                                        <span id='trechoDestinoIcons' className="fas fa-map-pin"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default withRouter(CardsTopTrechos);
